import accommodation from './accommodation'
import meal from './meal'
import participant from './participant'
import resourceReservation from './resourceReservation'
import task from './task'

export const salesForPrintFragments = Object.freeze({
  accommodation,
  meal,
  participant,
  resourceReservation,
  task,
})
