import styled, { css } from 'styled-components'

import { DateRange, DateRangePicker } from '@/components/TimeControls'

type Props = {
  setValue: (range?: DateRange) => void
  value?: DateRange
}

export const IntervalSelector = ({ setValue, value }: Props) => (
  <IntervalSelectorWrapper>
    <DateRangePicker
      disableFutureDays
      setValue={(range) => setValue(range || undefined)}
      value={value}
    />
  </IntervalSelectorWrapper>
)

/////////

const IntervalSelectorWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  ${({ theme }) => css`
    margin: -${theme.spacing.gu(1)}rem;
  `}
`
