import { gql } from '@apollo/client'

import { accommodationRoomReservationFragment } from './accommodation'
import purchaseFragment from './purchase'

const participantServiceBedFragment = gql`
  ${accommodationRoomReservationFragment}
  ${purchaseFragment}

  fragment ParticipantForSalesPrintServiceBed on ServiceParticipantBed {
    accommodationTarget {
      default
      id
      name
      sortOrder
    }
    dates {
      checkIn {
        date
      }
      checkOut {
        date
      }
    }
    id
    participantRoom {
      id
      roomReservation {
        ...AccommodationRoomReservationForSalesPrint
      }
    }
    purchaseProduct {
      ...PurchaseForSalesPrint
    }
  }
`

const participantServicePurchaseFragment = gql`
  ${purchaseFragment}

  fragment ParticipantForSalesPrintServicePurchase on ServicePurchase {
    id
    purchaseProduct {
      ...PurchaseForSalesPrint
    }
  }
`

export default gql`
  ${participantServiceBedFragment}
  ${participantServicePurchaseFragment}

  fragment ParticipantForSalesPrint on SalesParticipant {
    accommodationRequest
    additionalInfo
    age
    ageCategory {
      abbreviation
      key
      name
      shortName
      sortOrder
    }
    allergyDescription
    allergies
    diets
    drugAllergyDescription
    firstName
    id
    lastName
    group
    services {
      ...ParticipantForSalesPrintServiceBed
      ...ParticipantForSalesPrintServicePurchase
    }
  }
`
