import { gql } from '@apollo/client'

import { productFragments } from '@/modules/Products/fragments'
import { customerFragments } from '@/modules/Registry/Customer/fragments'
import { salesFragments } from '@/modules/Sales/fragments'

import TaskAssigneeFragment from './taskAssignee'

export const TaskFragment = gql`
  ${customerFragments.customerReference}
  ${productFragments.catalogProductOld}
  ${productFragments.productPurchase}
  ${salesFragments.salesFacetFeature}
  ${TaskAssigneeFragment}

  fragment Task on Task {
    assignee {
      ...TaskAssignee
    }
    auditLog {
      updatedAt
    }
    description
    dueDate
    dueTime
    id
    isOpen
    name
    number
    purchaseProducts {
      ...ProductPurchase
    }
    recommendedProducts {
      ...CatalogProductOld
    }
    reservation {
      description
      end
      group {
        name
        number
      }
      id
      internalNote
      resource {
        id
        name
        type
      }
      resourceQuantity
      start
    }
    sales {
      commissionRate
      customer {
        ...CustomerReference
      }
      facet {
        features {
          ...SalesFacetFeature
        }
        id
      }
      id
      lifecycle {
        state {
          key
          names {
            locale
            content
          }
          systemState
        }
      }
      locked
      name
      orderNumber
      orders(filterIsOpen: true) {
        id
        name
        sortOrder
      }
      seller {
        id
        name
      }
      type
    }
  }
`

export default TaskFragment
