import { ApolloError, useQuery } from '@apollo/client'

import {
  EmailSearchInput,
  EmailsSearchQuery as QueryData,
  EmailsSearchQueryVariables as QueryVariables,
} from '~generated-types'

import { emailsQueries } from '../queries'

export type SaleEmails = QueryData['emails']
type UseEmailsSearchResponse = {
  emails: SaleEmails | undefined
  error: ApolloError | undefined
  loading: boolean
  refetch: () => void
}

export const useEmailsSearch = ({
  filter,
  pagination,
  sort,
}: EmailSearchInput): UseEmailsSearchResponse => {
  const { data, error, loading, refetch } = useQuery<QueryData, QueryVariables>(
    emailsQueries.EmailsSearch,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        input: {
          filter,
          pagination,
          sort,
        },
      },
    }
  )

  return {
    emails: data?.emails,
    error,
    loading,
    refetch,
  }
}
