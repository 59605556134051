import { NavInternalViewType as Type,NavItemType } from '~generated-types'

import {
  KEYCLOAK_ADMIN_URL,
  STRAPI_ADMIN_URL,
  WILLBA_DOCS_URL,
  WILLBA_RELEASES_URL,
} from './externalUrls'

export const globalRoutes = {
  // Parent routes
  [NavItemType.Booking]: '/reservations',
  [NavItemType.FrontDesk]: '/front-desk',
  [NavItemType.Listing]: '/listings',
  [NavItemType.Registry]: '/registry',
  [NavItemType.Report]: '/reports',
  [NavItemType.Search]: '/search',

  // Booking routes
  [Type.BookingAccommodationReservation]: '/reservations/accommodation',
  [Type.BookingResourceCalendar]: '/reservations/calendar',
  [Type.BookingRoomLayout]: '/reservations/room-layout',

  // Frontdesk routes
  [Type.FrontDeskRoom]: '/front-desk/daily-room-reservations',
  [Type.FrontDeskSales]: '/front-desk/daily-customers',

  // Listing routes
  [Type.ListingPacketMeal]: '/listings/meals',
  [Type.ListingGuest]: '/listings/guests',
  [Type.ListingReservation]: '/listings/calendar-resources',
  [Type.ListingRoomReservation]: '/listings/room-reservations',
  [Type.ListingRoomReservationPajulahti]: '/listings/room-reservations-listing',
  [Type.ListingSales]: '/listings/sales',

  // Registry routes
  [Type.RegistryBuilding]: '/registry/buildings',
  [Type.RegistryCatalog]: '/registry/catalogs',
  [Type.RegistryCustomer]: '/registry/customers',
  [Type.RegistryProduct]: '/registry/products',
  [Type.RegistryEmail]: '/registry/emails',
  [Type.RegistryRoom]: '/registry/rooms',
  [Type.RegistryRoomType]: '/registry/room-types',
  [Type.RegistryStrapi]: STRAPI_ADMIN_URL,
  [Type.RegistryUser]: KEYCLOAK_ADMIN_URL,

  // Report routes
  [Type.ReportPayment]: '/reports/payments',
  [Type.ReportReservation]: '/reports/reservations',
  [Type.ReportOpenAdvance]: '/reports/open-advances',

  // Search routes
  [Type.SearchEnrollment]: '/search/enrollments',
  [Type.SearchInvoice]: '/search/invoices',
  [Type.SearchSales]: '/search/sales',
  [Type.SearchTask]: '/search/tasks',

  // External routes
  [Type.ExternalDocs]: WILLBA_DOCS_URL,
  [Type.ExternalReleases]: WILLBA_RELEASES_URL,
}
