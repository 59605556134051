import { Redirect, Switch, useRouteMatch } from 'react-router-dom'

import { NotFound } from '@/components/NotFound'
import { PageRoute } from '@/components/Page'
import { globalRoutes } from '@/config/globalRoutes'
import { useWillbaConfigContext } from '@/config/WillbaConfigContext'

import { NavInternalViewType as Type, NavItemType } from '~generated-types'

import { BuildingRoutes } from './Building/routes'
import { CatalogRoutes } from './Catalog/routes'
import { CustomerRoutes } from './Customer/routes'
import { ProductRoutes } from './Product/routes'
import { EmailRoutes } from './Email'
import { RoomRoutes } from './Room'
import { RoomTypeRoutes } from './RoomType'

export const RegistryRoutes = () => {
  const { path } = useRouteMatch()
  const { allNavDropdownInternalTypes } = useWillbaConfigContext()

  return (
    <Switch>
      <Redirect exact from={path} to={globalRoutes[Type.RegistryCustomer]} />

      {allNavDropdownInternalTypes.includes(Type.RegistryBuilding) && (
        <PageRoute
          component={BuildingRoutes}
          pageTitleKey={[
            `Navigation:navItem.${NavItemType.Registry}`,
            `Navigation:navDropdownItem.${Type.RegistryBuilding}`,
          ]}
          path={globalRoutes[Type.RegistryBuilding]}
        />
      )}

      {allNavDropdownInternalTypes.includes(Type.RegistryCatalog) && (
        <PageRoute
          component={CatalogRoutes}
          pageTitleKey={[
            `Navigation:navItem.${NavItemType.Registry}`,
            `Navigation:navDropdownItem.${Type.RegistryCatalog}`,
          ]}
          path={globalRoutes[Type.RegistryCatalog]}
        />
      )}

      {allNavDropdownInternalTypes.includes(Type.RegistryCustomer) && (
        <PageRoute
          component={CustomerRoutes}
          pageTitleKey={[
            `Navigation:navItem.${NavItemType.Registry}`,
            `Navigation:navDropdownItem.${Type.RegistryCustomer}`,
          ]}
          path={globalRoutes[Type.RegistryCustomer]}
        />
      )}

      {allNavDropdownInternalTypes.includes(Type.RegistryProduct) && (
        <PageRoute
          component={ProductRoutes}
          pageTitleKey={[
            `Navigation:navItem.${NavItemType.Registry}`,
            `Navigation:navDropdownItem.${Type.RegistryProduct}`,
          ]}
          path={globalRoutes[Type.RegistryProduct]}
        />
      )}

      {allNavDropdownInternalTypes.includes(Type.RegistryEmail) && (
        <PageRoute
          component={EmailRoutes}
          pageTitleKey={[
            `Navigation:navItem.${NavItemType.Registry}`,
            `Navigation:navDropdownItem.${Type.RegistryEmail}`,
          ]}
          path={globalRoutes[Type.RegistryEmail]}
        />
      )}

      {allNavDropdownInternalTypes.includes(Type.RegistryRoom) && (
        <PageRoute
          component={RoomRoutes}
          pageTitleKey={[
            `Navigation:navItem.${NavItemType.Registry}`,
            `Navigation:navDropdownItem.${Type.RegistryRoom}`,
          ]}
          path={globalRoutes[Type.RegistryRoom]}
        />
      )}

      {allNavDropdownInternalTypes.includes(Type.RegistryRoomType) && (
        <PageRoute
          component={RoomTypeRoutes}
          pageTitleKey={[
            `Navigation:navItem.${NavItemType.Registry}`,
            `Navigation:navDropdownItem.${Type.RegistryRoomType}`,
          ]}
          path={globalRoutes[Type.RegistryRoomType]}
        />
      )}

      <PageRoute pageTitle="404">
        <NotFound />
      </PageRoute>
    </Switch>
  )
}

export default RegistryRoutes
