import { gql } from '@apollo/client'

import { salesFragments } from '@/modules/Sales/fragments'

import orderCustomer from './orderCustomer'
import orderPacket from './orderPacket'

export default gql`
  ${orderCustomer}
  ${orderPacket}
  ${salesFragments.salesFacetFeature}

  fragment OrderShortInfo on Order {
    auditLog {
      createdAt
      updatedAt
    }
    customer {
      ...OrderCustomer
    }
    id
    items {
      ...OrderPacket
    }
    lifecycle {
      state
      validatedActions {
        action
        issues {
          code
        }
        valid
      }
    }
    name
    orderNumber
    paymentInfo {
      paid
      payableAmount
      totalPrice {
        amount
        amountVatIncluded
        vatAmount
      }
    }
    sales {
      commissionRate
      facet {
        features {
          ...SalesFacetFeature
        }
        id
      }
      id
      type
    }
    seller {
      id
      name
      shortName
    }
    sortOrder
  }
`
