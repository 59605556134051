import { ReactNode } from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'
import { ListItem, ListItemSection } from '@/components/List'

type Props = {
  columnCount: number
  content: ReactNode
  icon?: IconProp
}

export const ListPlaceholder = ({ columnCount, content, icon }: Props) => (
  <Wrapper>
    <ListItemSection colSpan={columnCount}>
      <FlexColumn alignItems="center" justifyContent="center">
        {icon && <Icon icon={icon} size="3x" />}
        <Content>{content}</Content>
      </FlexColumn>
    </ListItemSection>
  </Wrapper>
)

////////////

const Content = styled.div`
  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBigger};
  `}
`

const Icon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    color: ${theme.palette.smoke.dark};
    margin-bottom: ${theme.spacing.gu(2)}rem;
  `}
`

const Wrapper = styled(ListItem)`
  ${({ theme }) => css`
    height: ${theme.spacing.gu(30)}rem;
  `}
`
