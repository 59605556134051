import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { List, ListContent, ListControls } from '@/components/List'
import { H3 } from '@/components/Typography'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import {
  ListFilters,
  ListFooter,
  ListHeader,
  ListItem,
  ListPlaceholder,
  SelectedFilters,
  SortToggle,
} from './components'
import { useRegistersEmailsListContext } from './RegistersEmailsListState'

const COLUMN_COUNT = 7
const SIDEBAR_WIDTH = '320px'

export const RegistersEmailsList = () => {
  const { palette } = useTheme()

  const {
    statusFilterProps,
    dateIntervalProps,
    emails,
    error,
    loading,
    filters,
    searchFilterProps,
    clearAllFilters,
    handleSetSize,
    page,
    size,
    setPage,
    sortOptions,
    sortOrder,
    handleSetSortOrder,
  } = useRegistersEmailsListContext()

  const renderContent = () => {
    if (loading && !emails?.nodes.length) {
      return (
        <ListPlaceholder
          columnCount={COLUMN_COUNT}
          content={<ReactLoading color={palette.smoke.main} type="spin" />}
        />
      )
    }

    if (error) {
      return (
        <ListPlaceholder
          columnCount={COLUMN_COUNT}
          content={<T>ElasticFilterSearchList:error</T>}
          icon="circle-exclamation"
        />
      )
    }

    if (!emails?.nodes.length) {
      return (
        <ListPlaceholder
          columnCount={COLUMN_COUNT}
          content={<T>ElasticFilterSearchList:empty</T>}
          icon="circle-info"
        />
      )
    }

    return emails.nodes.map((email) => (
      <ListItem key={`registry-email-row-${email.id}`} email={email} />
    ))
  }

  return (
    <ListWrapper noPadding>
      <ListControlsWrapper>
        <ListControls>
          <FlexRow alignItems="flex-end" justifyContent="space-between">
            <ListTitle>
              <T>Emails:RegistersEmailList.title</T>
            </ListTitle>

            <SortToggle
              options={sortOptions}
              setValue={handleSetSortOrder}
              value={sortOrder}
            />
          </FlexRow>

          <FlexRow>
            <SelectedFilters
              clearAllFilters={clearAllFilters}
              filters={filters}
            />
          </FlexRow>
        </ListControls>
      </ListControlsWrapper>

      <ListContainer>
        <ListFiltersWrapper>
          <ListFilters
            searchFilterProps={searchFilterProps}
            statusFilterProps={statusFilterProps}
            dateIntervalProps={dateIntervalProps}
          />
        </ListFiltersWrapper>

        <List>
          <ListHeader />

          <ListContent>{renderContent()}</ListContent>

          {emails && (
            <ListFooter
              columnCount={COLUMN_COUNT}
              page={page}
              size={size}
              setPage={setPage}
              setSize={handleSetSize}
              totalElements={emails.totalElements}
              totalPages={emails.totalPages}
            />
          )}
        </List>
      </ListContainer>
    </ListWrapper>
  )
}

////////////

const ListContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`

const ListControlsWrapper = styled(FlexColumn)`
  margin-left: ${SIDEBAR_WIDTH};
`

const ListFiltersWrapper = styled(FlexColumn)`
  align-self: flex-start;
  align-items: stretch;
  border-radius: 8px;

  ${({ theme }) => css`
    flex: 0 0 calc(${SIDEBAR_WIDTH} - ${theme.spacing.gutter});
    padding: ${theme.spacing.gutter};
    margin-right: ${theme.spacing.gutter};
    background: ${theme.palette.white};
    border: solid 1px ${theme.palette.smoke.dark};
  `}
`

const ListWrapper = styled(FlexColumn)`
  max-width: 1500px;
  margin: auto;
`

const ListTitle = styled(H3)`
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gutter} ${theme.spacing.gutterSmall} 0;
  `}
`
