import { gql } from '@apollo/client'

export const ResourceReservationDimensionFragment = gql`
  fragment ResourceReservationDimensions on DimensionReference {
    id
    selection {
      dimension {
        description
        dimensionHierarchyRoot
        hierarchyName
        id
        name
        sortOrder
      }
      id
      selectedLabel {
        description
        id
        name
      }
      validatedOptions {
        id
        label {
          description
          id
          name
        }
        validationCategory
      }
    }
  }
`

export default ResourceReservationDimensionFragment
