import { translate } from '@/modules/Language'

import { EmailSortProperty as SortField, SortOrder } from '~generated-types'

export const getSortOrder = (
  direction: SortOrder,
  field: SortField,
  language: string
) => ({
  direction,
  field,
  label: translate(
    `Emails:RegistersEmailList.sort.${field}.${direction}`,
    language
  ),
})
