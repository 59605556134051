import { gql } from '@apollo/client'

import { salesFragments } from '@/modules/Sales/fragments'

export const CalendarResourceReservationFragment = gql`
  ${salesFragments.salesFacetFeature}

  fragment CalendarResourceReservation on ResourceReservation {
    description
    end
    group {
      end
      id
      name
      number
      start
    }
    id
    resource {
      id
      isPooled
    }
    resourceQuantity
    start
    sales {
      customer {
        customer {
          ... on CustomerOrganization {
            organization {
              name
            }
          }

          ... on CustomerPerson {
            person {
              firstName
              lastName
            }
          }
        }
      }
      facet {
        color
        features {
          ...SalesFacetFeature
        }
        id
      }
      id
      lifecycle {
        state {
          key
          names {
            locale
            content
          }
          systemState
        }
      }
      locked
      name
      orderNumber
    }
  }
`

export default CalendarResourceReservationFragment
