import { gql } from '@apollo/client'

export default gql`
  query EmailsSearch($input: EmailSearchInput!) {
    emails(input: $input) {
      nodes {
        id
        status
        details {
          to
          subject
          from
          createdAt
        }
        sales {
          id
          name
          orderNumber
        }
      }
      hasNextPage
      hasPreviousPage
      totalElements
      totalPages
    }
  }
`
