import {
  ListHeader as Wrapper,
  ListHeaderSection as Section,
} from '@/components/List'
import { T } from '@/modules/Language'

export const ListHeader = () => (
  <Wrapper>
    <Section width={'20%'}>
      <T>Emails:Fields.subject</T>
    </Section>

    <Section width={'20%'}>
      <T>Emails:Fields.to</T>
    </Section>

    <Section width={'20%'}>
      <T>Emails:Fields.from</T>
    </Section>

    <Section width={'10%'}>
      <T>Emails:Fields.status</T>
    </Section>

    <Section width={'10%'}>
      <T>Emails:Fields.sent</T>
    </Section>

    <Section width={'20%'}>
      <T>Emails:Fields.sales</T>
    </Section>
  </Wrapper>
)
