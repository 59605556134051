import styled, { css } from 'styled-components/macro'

import { IconButton } from '@/components/ExtraButtons'
import { Select } from '@/components/FormControls'
import { FlexRow } from '@/components/Layout'
import { ListFooter as Wrapper } from '@/components/List'
import { T, translate, useLanguageContext } from '@/modules/Language'

const PAGE_SIZES = [5, 10, 20, 50]

type Props = {
  columnCount: number
  page: number
  size: number
  setPage: (page: number) => void
  setSize: (size: number) => void
  totalElements: number
  totalPages: number
}

export const ListFooter = ({
  columnCount,
  page,
  size,
  setPage,
  setSize,
  totalElements,
  totalPages,
}: Props) => {
  const { language } = useLanguageContext()

  return (
    <Wrapper colSpan={columnCount}>
      <Container alignItems="center" justifyContent="space-between">
        <FooterLabel>
          <T>ElasticFilterSearchList:pageSize</T>:{' '}
          <PageSelect
            inline
            noMargin
            onChange={(e) => setSize(Number(e.target.value))}
            value={size}
          >
            {PAGE_SIZES.map((s) => (
              <option key={`page-size-${s}`} value={s}>
                {s}
              </option>
            ))}
          </PageSelect>
        </FooterLabel>

        <FooterLabel>
          <T>ElasticFilterSearchList:total</T>{' '}
          {translate('ElasticFilterSearchList:totalPostfix', language, {
            count: totalElements,
          })}
        </FooterLabel>

        <FlexRow alignItems="center">
          <IconButton
            color="transparent"
            disabled={page === 0}
            icon="angles-left"
            onClick={() => setPage(0)}
          />

          <IconButton
            color="transparent"
            disabled={page === 0}
            icon="angle-left"
            onClick={() => setPage(Math.max(page - 1, 0))}
          />

          <FooterLabel>
            <T>ElasticFilterSearchList:page</T>: {page + 1} / {totalPages}
          </FooterLabel>

          <IconButton
            color="transparent"
            disabled={page === totalPages - 1}
            icon="angle-right"
            onClick={() => setPage(Math.min(totalPages - 1, page + 1))}
          />

          <IconButton
            color="transparent"
            disabled={page === totalPages - 1}
            icon="angles-right"
            onClick={() => setPage(totalPages - 1)}
          />
        </FlexRow>
      </Container>
    </Wrapper>
  )
}

////////////

const Container = styled(FlexRow)`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.gu(1)}rem;
  `}
`

const FooterLabel = styled.span`
  font-weight: 500;

  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gu(1)}rem;
  `}
`

const PageSelect = styled(Select)`
  &:not([type='checkbox']):not([type='radio']) {
    ${({ theme }) => css`
      height: ${theme.spacing.gu(4)}rem;
    `}

    &:hover {
      cursor: pointer;
    }
  }
`
