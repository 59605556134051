import { SalesEmailsList } from '@/modules/Emails'

import { SectionContainer } from '../components'
import { SectionFeatures } from '../types'

const SECTION: SectionFeatures = {
  icon: 'envelope',
  key: 'emails',
}

const EmailsComponent = () => (
  <SectionContainer sectionIcon={SECTION.icon} keyName={SECTION.key}>
    <SalesEmailsList />
  </SectionContainer>
)

export const Emails = Object.freeze({
  component: EmailsComponent,
  icon: SECTION.icon,
  key: SECTION.key,
})
