import { ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'

type Props = {
  value?: string
  placeholder: ReactNode
}

export const CollapsedFilterValue = ({ placeholder, value }: Props) => (
  <Wrapper>
    <Row>
      {value ? (
        <Value>{value}</Value>
      ) : (
        <ValuePlaceholder>{placeholder}</ValuePlaceholder>
      )}
    </Row>
  </Wrapper>
)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6px;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(0.5)}rem ${theme.spacing.gu(1)}rem;
    background-color: ${theme.palette.smoke.lighter};
    border: 1px solid ${theme.palette.smoke.main};
  `}

  @media (max-width: 900px) {
    ${({ theme }) => css`
      padding: ${theme.spacing.gu(1)}rem;
    `}
  }
`

const Row = styled.div`
  display: inline-flex;
`

const Value = styled.span`
  font-weight: 500;
  flex: 1;
  text-align: center;
  white-space: pre-wrap;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}
`

const ValuePlaceholder = styled(Value)`
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`
