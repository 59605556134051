import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import { FontWeight } from '@/components/Typography'
import { translate, useLanguageContext } from '@/modules/Language'

import { useSalesEmailsListContext } from '../SalesEmailsState'

export const ListTotals = () => {
  const { language } = useLanguageContext()

  const {
    pageSize,
    pagination: { currentPage, totalElements, totalPages },
  } = useSalesEmailsListContext()

  const rangeShown =
    totalPages <= 1
      ? totalElements
      : `${currentPage * pageSize + 1} – ${Math.min(
          (currentPage + 1) * pageSize,
          totalElements
        )}`

  return (
    <Wrapper justifyContent="flex-end" alignItems="center">
      <FontWeight light>
        {translate(`Emails:SalesEmailsList.listTotals`, language, {
          range: rangeShown,
          total: totalElements,
        })}
      </FontWeight>
    </Wrapper>
  )
}

const Wrapper = styled(FlexRow)`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.gu(2)}rem;
    margin-right: ${theme.spacing.gu(2)}rem;
  `}
`
