import { EmailSortProperty as SortField, SortOrder } from '~generated-types'

import { getSortOrder } from './getSortOrder'

export const getSortOrderFromRoute = (
  routeSortOrder: string,
  language: string
) => {
  const match = routeSortOrder.match(/(.*)_(.*)/)

  return match
    ? getSortOrder(match[2] as SortOrder, match[1] as SortField, language)
    : null
}
