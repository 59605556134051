import { Chip, ChipColor } from '@/components/Chip'

import { EmailEventType } from '~generated-types'

const statusColor: Record<EmailEventType, ChipColor> = {
  BOUNCED: 'danger',
  CLICKED: 'success',
  COMPLAINED: 'danger',
  DELIVERED: 'success',
  DELIVERY_DELAYED: 'danger',
  OPENED: 'success',
  SCHEDULED: 'default',
  SENT: 'default',
}

type Props = {
  status: EmailEventType
}

export const StatusChip = ({ status }: Props) => (
  <Chip color={statusColor[status]} size="xs">
    {status}
  </Chip>
)
