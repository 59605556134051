import { Label } from '@/components/FormControls'
import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'

import { Section, VerticalDivider } from './ListComponents'

export const EmailsListHeader = () => (
  <FlexRow>
    <Section flex={1.3} noLeftMargin>
      <Label>{<T>Emails:Fields.subject</T>}</Label>
    </Section>

    <VerticalDivider />

    <Section flex={1}>
      <Label>
        <T>Emails:Fields.to</T>
      </Label>
    </Section>

    <VerticalDivider />

    <Section flex={1}>
      <Label>
        <T>Emails:Fields.from</T>
      </Label>
    </Section>

    <VerticalDivider />

    <Section flex={0.5}>
      <Label>
        <T>Emails:Fields.status</T>
      </Label>
    </Section>

    <VerticalDivider />

    <Section noRightMargin flex={0.5}>
      <Label>
        <T>Emails:Fields.sent</T>
      </Label>
    </Section>
  </FlexRow>
)
