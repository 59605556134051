import moment from 'moment'
import styled from 'styled-components'

import {
  ListItem as Wrapper,
  ListItemSection as Section,
} from '@/components/List'
import { Tooltip } from '@/components/Tooltip'
import { RegistryEmail } from '@/modules/Emails/types'

import { StatusChip } from '../../common'

type Props = {
  email: RegistryEmail
}

export const ListItem = ({ email }: Props) => {
  const parseDate = email.details?.createdAt
    ? moment(email.details.createdAt).format('DD.MM.YYYY HH:mm')
    : '-'

  const parsedSale = email.sales
    ? `#${email.sales.orderNumber} ${email.sales.name || ''}`
    : '-'

  return (
    <Wrapper style={{ height: '40px' }}>
      <StyledSection>
        <Tooltip
          content={email.details ? email.details.subject : '-'}
          delay={200}
          placement="bottom"
          maxWidth={250}
          trigger={(triggerProps) => (
            <Text {...triggerProps}>
              {email.details ? email.details.subject : '-'}
            </Text>
          )}
        />
      </StyledSection>

      <Section style={{ whiteSpace: 'nowrap' }}>
        {email.details ? email.details.to : '-'}
      </Section>

      <Section style={{ whiteSpace: 'nowrap' }}>
        {email.details ? email.details.from : '-'}
      </Section>

      <Section style={{ whiteSpace: 'nowrap' }}>
        {<StatusChip status={email.status} />}
      </Section>

      <Section style={{ whiteSpace: 'nowrap' }}>{parseDate}</Section>

      <StyledSection>
        <Tooltip
          content={parsedSale}
          delay={200}
          placement="bottom"
          maxWidth={250}
          trigger={(triggerProps) => (
            <Text {...triggerProps}>{parsedSale}</Text>
          )}
        />
      </StyledSection>
    </Wrapper>
  )
}

////////////

const StyledSection = styled(Section)`
  max-width: 150px;
`

const Text = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`
