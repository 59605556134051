import { gql } from '@apollo/client'

export default gql`
  fragment Dimension on Dimension {
    description
    dimensionHierarchyRoot
    hierarchyName
    id
    name
    sortOrder
    labels {
      description
      id
      name
    }
  }
`
