import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import isEqual from 'lodash.isequal'
import styled, { css } from 'styled-components/macro'

import {
  ActionDropdownButton,
  ActionDropdownList,
  ActionDropdownListItem,
} from '@/components/ActionDropdown/components'
import { Dropdown, DropdownRenderProps } from '@/components/Dropdown'
import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import {
  EmailSortProperty as SortField,
  SortOrder as SortDirection,
} from '~generated-types'

type SortOrder = {
  direction: SortDirection
  field: SortField
  label: string
}

type Props = {
  options: SortOrder[]
  setValue: (sortOrder: SortOrder) => void
  value: SortOrder
}

export const SortToggle = ({ options, setValue, value }: Props) => (
  <FlexRow alignItems="center" style={{ position: 'relative' }}>
    <DropdownLabel>
      <T>Reactivesearch:sort</T>:
    </DropdownLabel>

    <Dropdown
      noPadding
      positioning={{ right: '0', top: '32' }}
      renderControl={({ toggleDropdown }) => (
        <PickerButton onClick={toggleDropdown}>
          <PickerButtonLabel>{value.label}</PickerButtonLabel>
          <FontAwesomeIcon icon="caret-down" />
        </PickerButton>
      )}
      renderContent={({ toggleDropdown }: DropdownRenderProps) => (
        <ActionDropdownList>
          {options.map((o) => (
            <ActionDropdownListItem key={`${o.field}-${o.direction}`}>
              <OptionButton
                onClick={(e) => {
                  setValue(o)
                  toggleDropdown(e)
                }}
                type="button"
              >
                <ActiveIndicator isActive={isEqual(o, value)} />
                <OptionLabel>{o.label}</OptionLabel>
              </OptionButton>
            </ActionDropdownListItem>
          ))}
        </ActionDropdownList>
      )}
    />
  </FlexRow>
)

////////////

type ActiveIndicatorProps = {
  isActive: boolean
}

const ActiveIndicator = ({ isActive }: ActiveIndicatorProps) => {
  const theme = useTheme()

  return (
    <span style={{ flex: '0 0 1.5rem' }}>
      {isActive && (
        <FontAwesomeIcon
          color={theme.palette.text.light}
          icon="check"
          size="xs"
        />
      )}
    </span>
  )
}

const DropdownLabel = styled.span`
  font-weight: 500;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`

const OptionButton = styled(ActionDropdownButton)`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  width: 300px;
`

const OptionLabel = styled.span`
  flex: 1;
`

const PickerButton = styled.button`
  cursor: pointer;
  border-radius: 6px;
  font-weight: 500;

  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.dark};
    color: ${theme.palette.text.main};
    height: ${theme.spacing.gu(4)}rem;
    margin-bottom: ${theme.spacing.gutterSmall};
    margin-right: ${theme.spacing.gu(1)}rem;
    padding: 0 ${theme.spacing.gu(1)}rem;
  `}

  &:hover {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.lighter};
    `}
  }

  &:focus,
  &:active {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.light};
    `}
  }
`

const PickerButtonLabel = styled.span`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`
