import moment from 'moment'

import { FlexRow } from '@/components/Layout'
import { Pagination } from '@/components/Pagination'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { ContentSection } from '../../../Sales/components/SalesDetails/components'
import { StatusChip } from '../common'
import {
  ListContainerColumn,
  ListRow,
  ListWrapper,
  MessagePlaceholder,
  RowWrapper,
  Section,
  StyledSpinner,
  TruncateContent,
  VerticalDivider,
} from './components/ListComponents'
import { EmailsListHeader, ListTotals, ShowAllButton } from './components'
import { useSalesEmailsListContext } from './SalesEmailsState'

export const SalesEmailsList = () => {
  const theme = useTheme()

  const {
    pageSize,
    pagination,
    setPageSize,
    handleSwitchPage,
    emails,
    error,
    loading,
    showAllEmails,
  } = useSalesEmailsListContext()

  return (
    <RowWrapper>
      <ContentSection>
        <ListWrapper>
          <ListContainerColumn>
            {error ? (
              <MessagePlaceholder>
                <T>Emails:SalesEmailsList.error</T>
              </MessagePlaceholder>
            ) : loading ? (
              <StyledSpinner
                color={theme.palette.smoke.main}
                height={28}
                type={'spin'}
                width={28}
              />
            ) : !!emails?.nodes.length ? (
              <>
                <EmailsListHeader />

                {emails.nodes.map((email) => {
                  const parsedDates = moment(email.details?.createdAt).format(
                    'DD.MM.YYYY HH:mm'
                  )
                  return (
                    <ListRow key={`emails-list-row-${email.id}`}>
                      <Section flex={1.3} noLeftMargin>
                        <TruncateContent content={email.details?.subject} />
                      </Section>
                      <VerticalDivider />
                      <Section flex={1}>
                        <TruncateContent content={email.details?.to} />
                      </Section>
                      <VerticalDivider />
                      <Section flex={1}>
                        <TruncateContent content={email.details?.from} />
                      </Section>
                      <VerticalDivider />
                      <Section flex={0.5}>
                        <TruncateContent
                          content={<StatusChip status={email.status} />}
                        />
                      </Section>
                      <VerticalDivider />
                      <Section flex={0.5} noRightMargin>
                        <TruncateContent content={parsedDates} />
                      </Section>
                    </ListRow>
                  )
                })}
              </>
            ) : (
              <MessagePlaceholder>
                <T>Emails:SalesEmailsList.empty</T>
              </MessagePlaceholder>
            )}
          </ListContainerColumn>
        </ListWrapper>

        {!error && !loading && !!emails?.nodes.length && (
          <>
            <ListTotals />

            <FlexRow>
              {!showAllEmails && (
                <Pagination
                  pagination={pagination}
                  goToPage={handleSwitchPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  style={{ marginTop: 0 }}
                />
              )}
              {(pagination.hasNextPage ||
                pagination.hasPreviousPage ||
                showAllEmails) && <ShowAllButton />}
            </FlexRow>
          </>
        )}
      </ContentSection>
    </RowWrapper>
  )
}
