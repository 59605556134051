import { FilterSection } from '@/components/ElasticFilterSearchList'
import {
  DateIntervalProps,
  SearchFilterProps,
  StatusFilterProps,
} from '@/modules/Emails/types'
import { T, translate, useLanguageContext } from '@/modules/Language'

import {
  CollapsedFilterValue,
  IntervalSelector,
  SearchInput,
  StatusSelector,
} from './components'

type Props = {
  searchFilterProps: SearchFilterProps
  statusFilterProps: StatusFilterProps
  dateIntervalProps: DateIntervalProps
}

export const ListFilters = ({
  searchFilterProps,
  statusFilterProps,
  dateIntervalProps,
}: Props) => {
  const { language } = useLanguageContext()

  return (
    <>
      <FilterSection
        label={<T>Emails:RegistersEmailList.filter.search</T>}
        render={() => <SearchInput {...searchFilterProps} />}
      />

      <FilterSection
        label={<T>Emails:RegistersEmailList.filter.dates</T>}
        render={() => <IntervalSelector {...dateIntervalProps} />}
      />

      <FilterSection
        canCollapse
        forceBottomSpacing
        label={<T>Emails:RegistersEmailList.filter.status</T>}
        render={({ isCollapsed }) =>
          isCollapsed ? (
            <CollapsedFilterValue
              placeholder={
                <T>Emails:RegistersEmailList.filter.statusPlaceholder</T>
              }
              value={
                !!statusFilterProps.value.length
                  ? statusFilterProps.value
                      .map((v) => v.trim())
                      .map((v) =>
                        translate(
                          `Emails:RegistersEmailList.filter.statuses.${v}`,
                          language
                        )
                      )
                      .sort()
                      .join(', ')
                  : ''
              }
            />
          ) : (
            <StatusSelector {...statusFilterProps} />
          )
        }
      />
    </>
  )
}
