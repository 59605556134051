import { gql } from '@apollo/client'

import { taskFragments } from '@/modules/Task/fragments'

import purchaseFragment from './purchase'

export default gql`
  ${purchaseFragment}
  ${taskFragments.taskAssignee}

  fragment TaskForSalesPrint on Task {
    assignee {
      ...TaskAssignee
    }
    description
    dueDate
    dueTime
    id
    isOpen
    name
    number
    purchaseProducts {
      ...PurchaseForSalesPrint
    }
    reservation {
      id
    }
  }
`
