import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { Input } from '@/components/FormControls'
import { FlexRow } from '@/components/Layout'
import { translate, useLanguageContext } from '@/modules/Language'
import { useTheme } from '@/theme'

type Props = {
  value: string
  setValue: (value: string) => void
}

export const SearchInput = ({
  value: searchValue,
  setValue: setSearchValue,
}: Props) => {
  const { language } = useLanguageContext()
  const { palette } = useTheme()

  const [value, setValue] = useState<string>('')

  useEffect(() => {
    setValue(searchValue)
  }, [searchValue])

  return (
    <Wrapper justifyContent="flex-start" alignItems="center">
      <IconWrapper>
        <FontAwesomeIcon color={palette.primary.main} icon="magnifying-glass" />
      </IconWrapper>

      <Input
        value={value}
        placeholder={translate(
          'Emails:RegistersEmailList.filter.searchPlaceholder',
          language
        )}
        onChange={(e) => {
          setSearchValue(e.target.value)
          setValue(e.target.value)
        }}
      />
    </Wrapper>
  )
}

////////

const IconWrapper = styled.div`
  position: absolute;
  margin-left: 10px;
  width: 30px;
`

const Wrapper = styled(FlexRow)`
  input {
    &:not([type='checkbox']):not([type='radio']) {
      border-radius: 6px;
      padding-left: 30px;

      ${({ theme }) => css`
        border: 1px solid ${theme.palette.smoke.dark};
      `}

      &::placeholder {
        ${({ theme }) => css`
          color: ${theme.palette.text.lighter};
        `}
      }
    }
  }
`
