import { Route, Switch, useRouteMatch } from 'react-router-dom'

import { Gutter } from '@/components/Layout'
import { RegistersEmailsList } from '@/modules/Emails'

export const EmailRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route component={WrappedEmailsList} exact path={path} />
    </Switch>
  )
}

const WrappedEmailsList = () => {
  return (
    <Gutter type={[0, 5, 10]}>
      <RegistersEmailsList />
    </Gutter>
  )
}
