import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'

type Filter = {
  key: string
  label: string
  onClick: () => void
  value: string
  visible: boolean
}

type Props = {
  clearAllFilters: () => void
  filters: Filter[]
}

export const SelectedFilters = ({ clearAllFilters, filters }: Props) => (
  <Wrapper wrap="wrap">
    {filters
      .filter(({ visible }) => visible)
      .map(({ key, label, onClick, value }) => (
        <ClearButton key={key} onClick={onClick}>
          <ClearButtonLabel>
            {label}: {value}
          </ClearButtonLabel>

          <ClearButtonIcon>✕</ClearButtonIcon>
        </ClearButton>
      ))}

    {filters.some(({ visible }) => visible) && (
      <ClearAllButton onClick={clearAllFilters}>
        <T>ElasticFilterSearchList:resetFilters</T>
      </ClearAllButton>
    )}
  </Wrapper>
)

////////////

const ClearAllButton = styled.button`
  display: flex;
  align-items: center;

  border-radius: 6px;
  cursor: pointer;
  height: 28px;
  margin: 2px 3px;
  padding: 0 8px;
  position: relative;

  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.dark};
    color: ${theme.palette.text.main};
    font-size: ${theme.typography.fontSizeSmall};
  `}

  &:hover {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.lighter};
    `}
  }

  &:focus,
  &:active {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.light};
    `}
  }
`

const ClearButton = styled(ClearAllButton)`
  &:hover {
    text-decoration: line-through;
  }
`

const ClearButtonLabel = styled.span`
  max-width: 420px;
  margin-right: 26px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const ClearButtonIcon = styled(FlexRow)`
  position: absolute;
  height: 100%;
  top: 0;
  right: 8px;
  padding-left: 8px;
  margin-left: 8px;

  ${({ theme }) => css`
    border-left: 1px solid ${theme.palette.smoke.dark};
  `}
`

const Wrapper = styled(FlexRow)`
  margin: 0 -3px;
  max-width: 100%;
  min-height: 32px;
`
