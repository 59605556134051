import { useEffect, useMemo, useState } from 'react'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

import { BottomSheet } from '@/components/BottomSheet'
import { FlexColumn, Gutter } from '@/components/Layout'
import { NotFound } from '@/components/NotFound'
import { T } from '@/modules/Language'
import {
  ParticipantsListContextProvider,
  RoomGrouping,
} from '@/modules/ParticipantsList'
import { useLastUsedSalesContext } from '@/modules/Sales/contexts'

import { Feature as FeatureType, Visibility } from '~generated-types'

import { Emails as EmailsRoute } from './routes/Emails'
import { TasksChecks } from './routes/TasksChecks'
import { getSalesRoutes } from './utils/getSalesRoutes'
import {
  Header,
  PrintableContent,
  Sidebar,
  Topbar,
  WarningBanners,
} from './components'
import { SaleComponents } from './SaleComponents'
import { useSalesDetailsContext } from './SalesDetailsState'
import { SalesRoute } from './types'
import { getSectionIssueLevel, getSelectedViews } from './utils'

type Props = {
  baseRoute: string
  viewType: 'primary' | 'secondary'
}

export const SalesDetails = ({ baseRoute, viewType }: Props) => {
  const { addLastUsedSaleId } = useLastUsedSalesContext()
  const {
    data: { id, facet, tasks },
    embeddedActiveSections,
    setEmbeddedActiveSections,
    saleReadOnly,
    salesForPrint,
    salesIssues,
    emails,
  } = useSalesDetailsContext()

  const { search } = useLocation()
  const history = useHistory()

  useEffect(() => {
    viewType === 'secondary' &&
      !embeddedActiveSections.length &&
      setEmbeddedActiveSections(
        facet.features
          .filter(({ defaultVisibility }) => defaultVisibility === 'SHOWN')
          .map(({ feature }) => feature.toLowerCase())
      )
  }, [])

  const views =
    viewType === 'secondary' ? embeddedActiveSections : getSelectedViews(search)

  useEffect(() => {
    addLastUsedSaleId(id)

    const checksFeature: Feature = {
      defaultVisibility: Visibility.Shown,
      feature: 'checks',
    }

    type Feature = {
      defaultVisibility: Visibility
      feature: FeatureType | 'checks'
    }

    const defaultViews =
      viewType === 'primary'
        ? [...(tasks.length ? [checksFeature] : []), ...facet.features]
            .filter(
              ({ defaultVisibility }: Feature) => defaultVisibility === 'SHOWN'
            )
            .map(
              ({ feature }: Feature, index: number) =>
                `view[${index}]=${feature.toLowerCase()}`
            )
            .join('&')
        : null

    !views.length &&
      defaultViews &&
      history.replace(`${baseRoute}?${defaultViews}`)
  }, [])

  const [showAccommodation, setShowAccommodation] = useState<boolean>(false)

  const routes: SalesRoute[] = useMemo(() => {
    const emailsRoute = !!emails?.nodes.length ? [EmailsRoute] : []
    const taskCheckRoute = tasks.length ? [TasksChecks] : []
    const salesRoutes = getSalesRoutes()
      .filter((route) =>
        facet.features.find((f) => f.feature === route.key.toUpperCase())
      )
      .map((route) => {
        if (route.key === 'reservations') {
          const issue = getSectionIssueLevel(salesIssues?.reservationIssues)
          return issue ? { ...route, issue } : route
        }

        if (route.key === 'accommodation') {
          const issue = getSectionIssueLevel(salesIssues?.accommodationIssues)
          return issue ? { ...route, issue } : route
        }

        return route
      })

    return [...taskCheckRoute, ...salesRoutes, ...emailsRoute]
  }, [facet.features, tasks.length, salesIssues, emails])

  const WrappedNoSectionsView = () => (
    <EmptySectionsWrapper>
      <T>SalesDetails:section:none</T>
    </EmptySectionsWrapper>
  )

  const renderRoutes = () => (
    <Switch>
      <Redirect
        exact
        from={`${baseRoute}/overview`}
        to={`${baseRoute}?view[0]=overview`}
      />
      {!!views.length && (
        <SaleComponents routes={routes} views={views} baseRoute={baseRoute} />
      )}
      <Route exact path={baseRoute} component={WrappedNoSectionsView} />
      <Route>
        <NotFound />
      </Route>
    </Switch>
  )

  const renderView = {
    primary: (
      <>
        <Header />

        <ContentWrapper>
          <Sidebar
            baseRoute={baseRoute}
            routes={routes.map(({ component, ...route }) => route)}
            showAccommodation={() => setShowAccommodation(true)}
          />

          <FlexColumn flex={1}>
            <WarningBanners />

            <Content>{renderRoutes()}</Content>
          </FlexColumn>
        </ContentWrapper>
      </>
    ),
    secondary: (
      <>
        <Topbar
          baseRoute={baseRoute}
          routes={routes.map(({ component, ...route }) => route)}
        />

        <WarningBanners />

        <Content noPadding>{renderRoutes()}</Content>
      </>
    ),
  }

  return (
    <>
      <Wrapper>
        {renderView[viewType]}

        {showAccommodation && (
          <BottomSheet
            onClose={() => setShowAccommodation(false)}
            title={<T>SalesDetails:section.accommodation</T>}
          >
            <ParticipantsListContextProvider salesId={id}>
              <Gutter type={[0, 5, 10]}>
                <RoomGrouping readOnly={saleReadOnly} />
              </Gutter>
            </ParticipantsListContextProvider>
          </BottomSheet>
        )}
      </Wrapper>

      {salesForPrint && <PrintableContent salesForPrint={salesForPrint} />}
    </>
  )
}

////////////

const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  overflow: hidden;

  ${({ theme }) => css`
    background: ${theme.palette.smoke.extraLight};
  `}
`

const Content = styled.div<{ noPadding?: boolean }>`
  position: relative;
  overflow: auto;
  flex: 1 1 auto;
  padding: ${({ noPadding, theme }) =>
    noPadding
      ? 0
      : `${theme.spacing.gu(4)}rem ${theme.spacing.gu(2)}rem ${theme.spacing.gu(
          10
        )}rem`};

  @media print {
    display: none;
  }
`

const EmptySectionsWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100%;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBigger};
  `}
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  flex: 1;

  @media print {
    display: none;
  }
`
